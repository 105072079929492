import React, { useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import TermsModal from './utils/Modal';
import LoadingScreen from './utils/LoadingScreen';

import './../assets/css/ComingSoon.css';

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);
  const [resetZoom, setResetZoom] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert('Please complete Captcha');
      return;
    } else {
      setLoading(true);
    }

    const formData = {
      email,
      recaptchaValue,
    };


    try {
      const response = await fetch('https://block-stream-coming-soon-backend.onrender.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      } else {
        setLoading(false);
        setSuccess(true);
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      console.log(response);
    } catch {
      setLoading(false);
      setError(true);


      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const handlePageLoad = () => {
      setTimeout(() => {
        setOpacity(0);
      }, 200);
      setTimeout(() => {
        setLoaded(true);
      }, 400);
    };

    if (document.readyState === 'complete') {
      handlePageLoad();
    } else {
      window.addEventListener('load', handlePageLoad);
      document.addEventListener('DOMContentLoaded', handlePageLoad);
    }

    return () => {
      window.removeEventListener('load', handlePageLoad);
      document.removeEventListener('DOMContentLoaded', handlePageLoad);
    };
  }, []);


  useEffect(() => {
    const ensureViewportMetaTag = () => {
      let viewportMetaTag = document.querySelector('meta[name="viewport"]');
      if (!viewportMetaTag) {
        viewportMetaTag = document.createElement('meta');
        viewportMetaTag.name = 'viewport';
        document.head.appendChild(viewportMetaTag);
      }
      return viewportMetaTag;
    };

    const adjustZoom = () => {
      const viewportMetaTag = ensureViewportMetaTag();
      viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0');
    };


    setTimeout(() => {
      if (window.innerHeight === document.documentElement.clientHeight) {
        adjustZoom();
      }
    }, 100);
    setResetZoom(false);
  }, [resetZoom])

  return (
    <div className='comingSoonContent'>
      {!loaded && <LoadingScreen opacity={opacity} />}
      <div className='container'>
        <div className='logo'>
          <div className='logoIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="36" viewBox="0 0 9 36" fill="none">
              <ellipse cx="4.5" cy="18" rx="0" ry="0" fill="black">
                <animate attributeName="rx" from="0" to="4.5" dur="0.3s" fill="freeze" begin="0.7s" />
                <animate attributeName="ry" from="0" to="18" dur="0.3s" fill="freeze" begin="0.7s" />
              </ellipse>
            </svg>
          </div>
          <h1 className='logoText'>Block Stream Technology</h1>
        </div>
        <div className='comingSoon'>
          <h2 className='comingSoonText'><span>C</span><span>o</span><span>m</span><span>i</span><span>n</span><span>g</span> <span>S</span><span>o</span><span>o</span><span>n</span></h2>
          <p className='waitlist'>Subscribe to enter the waitlist</p>
          <p className='waitlistText'>Subscribe now and be the first to receive notifications about exclusive beta access to our upcoming products. </p>
          <form onSubmit={handleSubmit}>
            <div className='form'>
              <input
                type="email"
                name='email'
                placeholder="E-mail address.."
                value={email}
                onInvalid={(e) => { e.target.setCustomValidity('Enter your mail address');; }}
                onChange={(e) => setEmail(e.target.value)}
                onClick={() => { setResetZoom(true); }}
                blurOnSubmit={true}
                required
                className="mailInput"
              />
              <button className={`button ${loading ? 'loading' : ''} ${success ? 'success' : ''} ${error ? 'error' : ''}`} type="submit" disabled={loading || success} >
                {loading ? (
                  <div className="spinner"></div>
                ) : success ? (
                  'Success!'
                ) : error ? (
                  'Error!'
                ) : (
                  'Subscribe'
                )}
              </button>
            </div>
            <ReCAPTCHA
              className='captcha'
              ref={recaptchaRef}
              sitekey="6LeqL0MqAAAAAI3gWb0cNTpT1XQ5ja3hLVk4JAH0"
              onChange={handleRecaptcha}
            />
          </form>

          <p className='textTerms'>
            By clicking subscribe you agree to our: <button onClick={() => openModal()} className='buttonTerms' >Terms & Conditions</button>
          </p>
        </div>
      </div>
      <div className='backContainer'>
      </div>
      <TermsModal isOpen={modalIsOpen} onClose={closeModal} />
    </div>
  );
};

export default ComingSoon;
