import React from 'react';
import Modal from 'react-modal';
import '../../assets/css/Modal.css';

Modal.setAppElement('#root');  // Necesario para la accesibilidad

const TermsModal = ({ isOpen, onClose }) => {
    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose} // Permite cerrar con "Esc" o al hacer clic fuera
                shouldCloseOnOverlayClick={true} // Cierra el modal al hacer clic fuera de él
                contentLabel="Terms and Conditions"
                className="modal-content"
                overlayClassName="modal-overlay"
            >

                <h2 className='termTitle'>Terms and Conditions</h2>
                <div className="modal-close-button">
                    <button onClick={onClose} className="close-button">
                        &times;
                    </button>
                </div>

                <h3 className='agreement'>Your Agreement</h3>
                <div className="termsContent">
                    <p><strong>Last updated:</strong> 09/2024</p>
                    <p>
                        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using
                        the Block Stream Technology website (the "Service") operated by Block Stream Technology ("we",
                        "our" or "us"). By accessing or using the Service, you agree to be bound by these Terms. If you
                        do not agree with any part of the terms, you must not access the Service.
                    </p>

                    <h3>1. <strong>Acceptance of the Terms</strong></h3>
                    <p>
                        By using our Service, you confirm that you have read, understood, and accepted these Terms and
                        Conditions, as well as our Privacy Policy. If you do not accept these Terms, you must immediately
                        leave the site.
                    </p>

                    <h3>2. <strong>Service Description</strong></h3>
                    <p>
                        Block Stream Technology is a website hosted on OnRender, accessible from anywhere in the world.
                        We provide users with the opportunity to subscribe to our service, through which we collect email
                        addresses to send information related to technology products and services, including access to
                        beta versions of our products.
                    </p>

                    <h3>3. <strong>Information Collection</strong></h3>
                    <p>
                        To subscribe to our Service, we request your email address. This information will be used
                        exclusively for the following purposes:
                    </p>
                    <ul>
                        <li>Sending emails with relevant information about our products and services.</li>
                        <li>Providing early access or invitations to participate in beta testing of our products.</li>
                    </ul>
                    <p>
                        By providing us with your email, you agree that we may send you such information.
                    </p>

                    <h3>4. <strong>Data Protection</strong></h3>
                    <p>
                        We are committed to protecting your personal information. All information collected will be
                        handled in accordance with our <strong>Privacy Policy</strong>, which details how we collect,
                        use, and protect your information. We do not share your email or any other personal information
                        with third parties without your consent, except where required by law.
                    </p>

                    <h3>5. <strong>Beta Access</strong></h3>
                    <p>
                        Access to any beta version of our products is limited and subject to availability. Submitting
                        an email to subscribe does not guarantee participation in a beta. We reserve the right to select
                        users who will receive access based on internal criteria.
                    </p>

                    <h3>6. <strong>Intellectual Property</strong></h3>
                    <p>
                        All content on the website, including texts, images, graphics, logos, and software, is the
                        exclusive property of Block Stream Technology or its licensors and is protected by intellectual
                        property laws. You do not have the right to use, reproduce, or distribute the content without
                        our express written consent.
                    </p>

                    <h3>7. <strong>Limitation of Liability</strong></h3>
                    <p>
                        Our Service is provided "as is" and "as available." We do not guarantee that the Service will
                        be uninterrupted, secure, or error-free. We are not liable for any direct, indirect, incidental,
                        or consequential damages resulting from the use or inability to use our Service.
                    </p>

                    <h3>8. <strong>Changes to the Terms</strong></h3>
                    <p>
                        We reserve the right to modify or replace these Terms at any time. Any changes will be notified
                        through the website or via email. Your continued use of the Service after any modification implies
                        acceptance of the new terms.
                    </p>

                    <h3>9. <strong>Jurisdiction</strong></h3>
                    <p>
                        These Terms will be governed and interpreted in accordance with applicable laws. Any dispute that
                        arises in relation to these Terms will be submitted to the exclusive jurisdiction of the competent
                        courts of the location of Block Stream Technology.
                    </p>

                    <h3>10. <strong>Contact</strong></h3>
                    <p>
                        If you have any questions about these Terms and Conditions, you can contact us at <strong>team@blockstream.technology</strong>.
                    </p>

                    <p><strong>By using our Service, you confirm that you have read and accepted these Terms and Conditions.</strong></p>
                </div>


                <div className="button-container">
                    <button onClick={onClose} className="agree-button">Close</button>
                </div>

            </Modal>
        </div>
    );
}

export default TermsModal;
