import React from 'react';
import '../../assets/css/LoadingScreen.css';

const LoadingScreen = ({ opacity }) => {
    return (
        <div className="loading-overlay" style={{
            transition: 'opacity 0.5s ease-in-out',
            opacity: opacity,
        }}>
            <div className="loading-bar-container">
                <div className="loading-bar active"></div>
            </div>
        </div>
    );
};

export default LoadingScreen;
